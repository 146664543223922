<template>
  <!-- <Code to place Login dialog in the center of the screen > -->
  <v-dialog persistent width="600" v-model="isLoginDialog">
    <v-card>
      <v-card-title primary-title>{{ $t("Login") }}</v-card-title>
      <v-card-text>
        <v-form ref="loginForm" @submit="submitLogin" onSubmit="return false;">
          <v-text-field autofocus ref="username" :label="$t('Username')" v-model="userName" :rules="[rules.required]"/>
          <v-text-field 
            :label="$t('Password')"
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            :rules="[rules.required]"
            @click:append="show = !show"/>
          <v-row no-gutters justify="space-around">
            <v-col cols="auto">
              <v-btn text type="submit" 
                :loading="isLoading" 
                :disabled="this.userName && this.password ? false : true">{{ $t("Login") }}
                <template v-if="isLoading" v-slot:loader>
                  <span>{{ $t("Logging") + "..." }}</span>
                </template>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn text :disabled="isCancelDisable" @click="cancelLogin">{{ $t("Cancel") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import apiLogin from "@/api/login";
import validations from "@/util/validations";
import HereStoreUtil from "@/util/hereStore";

export default {
  name: "login",
  data() {
    return {
      serverName: null,
      userName: null,
      password: null,
      show: false,
      loginErrorMessage: "",
      isLoading: false,
      rules: { required: validations.required }
    };
  },
  computed: {
    ...mapState({
      storeServerName: state => state.userDefaults.latlongoServer,
      hereConfigApiKey: state => state.config.here_licenses.hereConfigApiKey,
      isLoginDialog: state => state.appMode ? state.appMode === "login" : true,
      isCancelDisable: state => (state.userName === " - " ? true : false)
    })
  },
  created() {
    console.log("...Login panel created.");
  },
  mounted() {
    this.serverName = localStorage["server-name"];
    /**
     * this.isRemoteServer = this.serverName != window.location.hostname;
     * Not following isRemoteServer since servername to be passed by query parameter 
     * if needed to be overwritten e.g. local development environment.
     */
    if (window.location.search) {
      let query = window.location.search;
      query = query.indexOf("%") >= 0 ? decodeURIComponent(query) : query;
      let urlParams = new URLSearchParams(query);
      this.$store.dispatch("home/handlePermalinkDetails", urlParams);
    } else {
      // Set the value of permalink as undefined.
      this.$store.commit("home/changePermalink", undefined);
    }
  },

  methods: {
    submitLogin() {
      let hereStoreUtil = new HereStoreUtil();
      window.getApp.$emit("CLOSE_FLASH_MESSAGE");
      this.isLoading = true;
      this.loginErrorMessage = "";
      if (this.userName && this.password) {
        let payload = { username: this.userName, password: this.password };
        apiLogin.postLogin(payload).then(response => {
          localStorage["latlongo-api-key"] = response.headers["latlongo-api-key"];
          hereStoreUtil.handlePostLogin(payload.username, this.$store).then(status => {
            this.isLoading = false;
            this.$refs.loginForm.reset();
          });
        }, error => {
          this.isLoading = false;
          if (error.response?.status === 401) {
            this.loginErrorMessage = "Username or password is incorrect (401)";
          } else if (error.response?.status === 403) {
            this.loginErrorMessage = "No more licenses available OR License already expired (403)";
          } else if (error.response?.status === 426) {
            this.loginErrorMessage = "Kerberos single sign on attempted, but a NTLM ticket was sent (426)";
          } else if (error.response?.status === 500) {
            this.loginErrorMessage = "An unknown error occurred on server (500)";
          } else {
            this.loginErrorMessage = "An unknown error occurred on server.";
          }
          window.getApp.$emit("FLASH_MESSAGE", { text: this.loginErrorMessage, type: "error" });
        });
      }
    },
    cancelLogin() {
      this.$refs.loginForm.reset();
      this.loginErrorMessage = "";
      this.$store.dispatch("handleBack");
      this.isLoading = false;
    }
  }
};
</script>
